export const state = () => ({
    doctors: null,
    feedbacks: null,
    banners: [],
    medias: [],
    specialists: [],
});

export const getters = {
};

export const mutations = {
    SET_STATE(state, { prop, data }) {
        state[prop] = data;
    },
};

export const actions = {
    async fetchAll({ commit }, params) {
        const { data: { banners } } = await this.$api.introduction.getBanners(params);
        commit('SET_STATE', { prop: 'banners', data: banners || [] });
    },
    async fetchDoctor({ commit }, params) {
        const { data: { doctors } } = await this.$api.doctors.getShowHome(params);
        commit('SET_STATE', { prop: 'doctors', data: doctors || [] });
    },
    async fetchMedias({ commit }, params) {
        const { data: { medias } } = await this.$api.introduction.getMediasShowHome(params);
        commit('SET_STATE', { prop: 'medias', data: medias || [] });
    },
    async fetchFeedbacks({ commit }, params) {
        const { data: { feedbacks } } = await this.$api.introduction.getFeedbacks(params);
        commit('SET_STATE', { prop: 'feedbacks', data: feedbacks || [] });
    },
    async fetchSpecialists({ commit }, params) {
        const { data: { specialists } } = await this.$api.introduction.getSpecialist(params);
        commit('SET_STATE', { prop: 'specialists', data: specialists || [] });
    },
};
