import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _823dff9a = () => interopDefault(import('../pages/chuyen-khoa/index.vue' /* webpackChunkName: "pages/chuyen-khoa/index" */))
const _1434e84b = () => interopDefault(import('../pages/dich-vu/index.vue' /* webpackChunkName: "pages/dich-vu/index" */))
const _432c9c81 = () => interopDefault(import('../pages/gioi-thieu/index.vue' /* webpackChunkName: "pages/gioi-thieu/index" */))
const _4329a166 = () => interopDefault(import('../pages/lien-he/index.vue' /* webpackChunkName: "pages/lien-he/index" */))
const _d8990f52 = () => interopDefault(import('../pages/tin-tuc/index.vue' /* webpackChunkName: "pages/tin-tuc/index" */))
const _37058083 = () => interopDefault(import('../pages/tuyen-dung/index.vue' /* webpackChunkName: "pages/tuyen-dung/index" */))
const _09c0d79b = () => interopDefault(import('../pages/cham-soc-khach-hang/bao-hiem-y-te.vue' /* webpackChunkName: "pages/cham-soc-khach-hang/bao-hiem-y-te" */))
const _05264f92 = () => interopDefault(import('../pages/cham-soc-khach-hang/cau-hoi-thuong-gap.vue' /* webpackChunkName: "pages/cham-soc-khach-hang/cau-hoi-thuong-gap" */))
const _f914e1f0 = () => interopDefault(import('../pages/cham-soc-khach-hang/dang-ky-kham-benh.vue' /* webpackChunkName: "pages/cham-soc-khach-hang/dang-ky-kham-benh" */))
const _4bee7798 = () => interopDefault(import('../pages/gioi-thieu/doi-ngu-y-bac-si.vue' /* webpackChunkName: "pages/gioi-thieu/doi-ngu-y-bac-si" */))
const _2d00b67d = () => interopDefault(import('../pages/gioi-thieu/thu-vien-hinh-anh.vue' /* webpackChunkName: "pages/gioi-thieu/thu-vien-hinh-anh" */))
const _45a1a47f = () => interopDefault(import('../pages/tin-tuc/_id.vue' /* webpackChunkName: "pages/tin-tuc/_id" */))
const _43efc7ab = () => interopDefault(import('../pages/tuyen-dung/_id.vue' /* webpackChunkName: "pages/tuyen-dung/_id" */))
const _0547af50 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _9156fcda = () => interopDefault(import('../pages/_slug/index.vue' /* webpackChunkName: "pages/_slug/index" */))
const _94c43f6a = () => interopDefault(import('../pages/_slug/_slug.vue' /* webpackChunkName: "pages/_slug/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/chuyen-khoa",
    component: _823dff9a,
    name: "chuyen-khoa"
  }, {
    path: "/dich-vu",
    component: _1434e84b,
    name: "dich-vu"
  }, {
    path: "/gioi-thieu",
    component: _432c9c81,
    name: "gioi-thieu"
  }, {
    path: "/lien-he",
    component: _4329a166,
    name: "lien-he"
  }, {
    path: "/tin-tuc",
    component: _d8990f52,
    name: "tin-tuc"
  }, {
    path: "/tuyen-dung",
    component: _37058083,
    name: "tuyen-dung"
  }, {
    path: "/cham-soc-khach-hang/bao-hiem-y-te",
    component: _09c0d79b,
    name: "cham-soc-khach-hang-bao-hiem-y-te"
  }, {
    path: "/cham-soc-khach-hang/cau-hoi-thuong-gap",
    component: _05264f92,
    name: "cham-soc-khach-hang-cau-hoi-thuong-gap"
  }, {
    path: "/cham-soc-khach-hang/dang-ky-kham-benh",
    component: _f914e1f0,
    name: "cham-soc-khach-hang-dang-ky-kham-benh"
  }, {
    path: "/gioi-thieu/doi-ngu-y-bac-si",
    component: _4bee7798,
    name: "gioi-thieu-doi-ngu-y-bac-si"
  }, {
    path: "/gioi-thieu/thu-vien-hinh-anh",
    component: _2d00b67d,
    name: "gioi-thieu-thu-vien-hinh-anh"
  }, {
    path: "/tin-tuc/:id?",
    component: _45a1a47f,
    name: "tin-tuc-id"
  }, {
    path: "/tuyen-dung/:id?",
    component: _43efc7ab,
    name: "tuyen-dung-id"
  }, {
    path: "/",
    component: _0547af50,
    name: "index"
  }, {
    path: "/:slug",
    component: _9156fcda,
    name: "slug"
  }, {
    path: "/:slug/:slug",
    component: _94c43f6a,
    name: "slug-slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
