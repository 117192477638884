
    import { mapState } from 'vuex';

    export default {
        components: {
        },

        async fetch() {
            this.fetchData();
        },

        data() {
            return {
                loading: false,
                menus: [],
            };
        },

        computed: {
            ...mapState('settings', ['setting']),
            specialist() {
                return this.menus.find((e) => (e.slug === 'chuyen-khoa'));
            },
        },

        methods: {
            async fetchData() {
                try {
                    this.loading = true;
                    const { data: { data: { menus } } } = await this.$axios.get('/u/menus?origin=benhvien16a.com');
                    this.menus = menus;
                    if (!this.setting) {
                        await this.$store.dispatch('settings/fetchAll');
                    }
                } catch (error) {
                    this.$handleError(error);
                } finally {
                    this.loading = false;
                }
            },
        },
    };
