export const state = () => ({
    doctors: [],
    medias: [],
});

export const getters = {
};

export const mutations = {
    SET_STATE(state, { prop, data }) {
        state[prop] = data;
    },
};

export const actions = {
    async fetchDoctors({ commit }, params) {
        const { data: { doctors } } = await this.$api.introduction.getDoctors(params);
        commit('SET_STATE', { prop: 'doctors', data: doctors || [] });
    },

    async fetchMedias({ commit }, params) {
        const { data: { medias } } = await this.$api.introduction.getMedias(params);
        commit('SET_STATE', { prop: 'medias', data: medias || [] });
    },
};
