
    import { mapState } from 'vuex';

    export default {
        components: {
        },

        async fetch() {
            await this.fetchData();
        },

        data() {
            return {
                openKeys: [],
                isViewCart: false,
                isShowMenu: false,
                isTablet: false,
                isShowCart: true,
                menuItems: [],
                pathRouter: null,
            };
        },
        computed: {
            ...mapState('settings', ['setting']),
            activeKeys() {
                return [this.pathRouter];
            },

            currentUser() {
                return this.$auth.user || undefined;
            },
        },

        watch: {
            '$route.path': {
                handler() {
                    this.hideMenu();
                    this.pathRouter = this.$route.path;
                    console.log(this.pathRouter);
                },
            },
        },

        async mounted() {
            this.isTablet = window.innerWidth < 1280;
        },

        methods: {
            async fetchData() {
                try {
                    this.loading = true;
                    const { data: { data: { menus } } } = await this.$axios.get('/u/menus?origin=benhvien16a.com');
                    menus?.map((e) => ({
                        label: e.title,
                        key: e.slug,
                    }));
                    this.menuItems = menus.map((e) => ({
                        label: e.title,
                        key: `/${e.slug}`,
                        childrens: (e.children || []).map((o) => ({
                            label: o.title,
                            key: `/${o.slug}`,
                            childrens: [],
                        })),
                    }));
                    if (!this.setting) {
                        await this.$store.dispatch('settings/fetchAll');
                    }
                    if (this.$cookies.get('sessionTracking')) {
                        this.$axios.post('/u/analystic/view', {
                            sessionTracking: this.$cookies.get('sessionTracking'),
                            domain: 'benhvien16a.com',
                        });
                    } else {
                        const { data: { data: { session } } } = await this.$axios.post('/u/analystic/view', { domain: 'benhvien16a.com' });
                        this.$cookies.set('sessionTracking', session, '120h');
                    }
                } catch (error) {
                    // this.$handleError(error);
                } finally {
                    this.loading = false;
                }
            },

            viewCard() {
                this.isViewCart = !this.isViewCart;
            },

            hiddenCart() {
                this.isViewCart = false;
            },

            showMenu() {
                this.isShowMenu = true;
            },

            hideMenu() {
                this.isShowMenu = false;
            },

            handleClick({ key }) {
                this.$router.push(key);
            },

            handleOpenChange(keys) {
                this.openKeys = keys?.length ? [keys?.pop()] : [];
            },
        },
    };
