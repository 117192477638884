export const state = () => ({
    setting: null,
});

export const getters = {
};

export const mutations = {
    SET_STATE(state, { prop, data }) {
        state[prop] = data;
    },
};

export const actions = {
    async fetchAll({ commit }, params) {
        const { data: { setting } } = await this.$api.settings.getAll(params);
        commit('SET_STATE', { prop: 'setting', data: setting || [] });
    },
};
