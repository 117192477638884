
    import _isEmpty from 'lodash/isEmpty';
    import EmptyPage from '@/components/shared/EmptyPage.vue';

    export default {
        components: {
            EmptyPage,
        },
        // async fetch() {
        //     await this.fetchData();
        // },
        data() {
            return {
                data: {},
                loading: false,
            };
        },
        methods: {
            _isEmpty,
            // async fetchData() {
            //     try {
            //         this.loading = true;
            //         // if (_isEmpty(this.data)) {
            //         console.lot(this.$router);
            //         await this.$axios.get('/u/page/');
            //         // }
            //     } catch (error) {
            //         // this.$handleError(error);
            //     } finally {
            //         this.loading = false;
            //     }
            // },
        },
        head() {
            return {
                title: 'Tuyển dụng',
            };
        },
    };
