export const state = () => ({
    recruitments: [],
});

export const getters = {
};

export const mutations = {
    SET_STATE(state, { prop, data }) {
        state[prop] = data;
    },
};

export const actions = {
    async fetchAll({ commit }, params) {
        const { data: { recruitments } } = await this.$api.recruitments.getAll(params);
        commit('SET_STATE', { prop: 'recruitments', data: recruitments || [] });
    },
};
