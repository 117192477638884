import _values from 'lodash/values';
import { message as $message } from 'ant-design-vue';

export default function ({ $axios, redirect }, inject) {
    $axios.onError((error) => {
        const code = error?.response ? parseInt(error.response.status, 10) : null;
        if (code === 401) {
            redirect('/login');
        }
    });
    const handleError = (error, callback = null) => {
        const errorData = error?.response?.data?.error;
        if (error?.response?.status === 422) {
            const values = _values(errorData.messages);
            values.forEach((item) => {
                item?.forEach((message) => {
                    $message.error(message);
                });
            });
        } else if (callback) {
            callback(error);
        } else {
            $message.error('Có lỗi xảy ra vui lòng thử lại sau');
        }
    };

    inject('handleError', handleError);
}
