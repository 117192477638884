export const state = () => ({
    posts: [],
    postsShowHome: [],
    categories: [],
    mostViews: [],
    relates: [],
    pagination: {},
});

export const getters = {
};

export const mutations = {
    SET_STATE(state, { prop, data }) {
        state[prop] = data;
    },
};

export const actions = {
    async fetchAll({ commit }, params) {
        const { data: { blogs, pagination } } = await this.$api.posts.getAll(params);
        commit('SET_STATE', { prop: 'posts', data: blogs || [] });
        commit('SET_STATE', { prop: 'pagination', data: pagination || {} });
    },
    async fetchCategories({ commit }, params) {
        const { data: { categories } } = await this.$api.posts.getAllCategories(params);
        commit('SET_STATE', { prop: 'categories', data: categories || [] });
    },
    async fetchRelates({ commit }, params) {
        const { data: { blogs } } = await this.$api.posts.getRelates(params);
        commit('SET_STATE', { prop: 'relates', data: blogs || [] });
    },
    async fetchMostViews({ commit }, params) {
        const { data: { blogs } } = await this.$api.posts.getMostViews(params);
        commit('SET_STATE', { prop: 'mostViews', data: blogs || [] });
    },
    async fetchShowHome({ commit }, params) {
        const { data: { blogs } } = await this.$api.posts.getShowHome(params);
        commit('SET_STATE', { prop: 'postsShowHome', data: blogs || [] });
    },
};
